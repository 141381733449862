import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px 0px;

  a {
    color: var(--colors-site-lightAccent);
    font-weight: 600;
  }
`;

export const ImageBlock = styled.img`
  height: 100%;
  object-fit: cover;
  // max-height: 400px;
  // aspect-ratio: 16/9;
`;

export const Tryptych = styled.div`
  margin-top: 20px;
  padding: 0px 20px;
  display: flex;
  gap: 20px;
  flex-shrink: 1;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const TryptychImage = styled.img`
  width: 30%;
  height 30%:
  object-fit: cover;
  flex: 1 1 400px;

  @media screen and (max-width: 800px) {
    width: 80%;
    height: 80%;
  }
`;
